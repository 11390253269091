export const getSliderColor = (value: number) => {
  if (value <= 50) {
    return '#2D0A3E';
  }
  if (value > 50 && value <= 90) {
    return '#A03D96';
  }
  if (value > 90) {
    return '#C83995';
  }
  return '#797779';
};
