export enum ProjectTag {
  ALL = 'All',
  REACT = 'React',
  NODE = 'Node',
  WEB = 'Web',
  SOFTWARE = 'Software',
  AI = 'AI',
}

export type Project = {
  key: string;
  title: string;
  description?: string;
  tech?: string[];
  tags?: ProjectTag[];
  link?: string;
  code?: string;
  screenshot?: string;
};
