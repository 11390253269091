import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import { ProjectTag } from '../../resources/types';
import colors from '../../theme/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setTag } from '../../store/reducers';
import { RootState } from '../../store';

interface FilterButtonProps {
  title: ProjectTag;
}

const FilterButton: FC<FilterButtonProps> = ({ title }) => {
  const dispatch = useDispatch();

  const projectFilter = useSelector(
    (state: RootState) => state.project.selectedTag
  );

  const setFilter = () => {
    dispatch(setTag(title));
  };

  return (
    <Grid item md={2}>
      <Button
        variant={projectFilter === title ? 'contained' : 'outlined'}
        sx={{
          '&:hover': {
            color: projectFilter === title ? 'auto' : colors.primary,
          },
        }}
        fullWidth
        onClick={setFilter}
      >
        {title}
      </Button>
    </Grid>
  );
};

export { FilterButton };
