import { Button, Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useForm } from '@formspree/react';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { useNavigate } from 'react-router-dom';

const ThankYouPage: FC = () => {
  const navigate = useNavigate();

  const navigateToServices = () => {
    navigate('/projects');
  };

  return (
    <Grid container spacing={2} textAlign="left">
      <Grid item xs={12}>
        <Typography variant="h2">Thank you for your message!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          We will get back to you within 24-48 hours.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          For now, check out some of my projects!
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop="50px">
        <Button
          onClick={navigateToServices}
          variant="contained"
          color="secondary"
          startIcon={<RocketLaunchOutlinedIcon />}
          sx={{ p: '10px 20px' }}
        >
          Go to Projects
        </Button>
      </Grid>
    </Grid>
  );
};

const ContactForm: FC = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const formKey = process.env.REACT_APP_FORM_KEY;
  if (!formKey) throw new Error('Form key not found');

  const [state, handleSubmit] = useForm(formKey);

  const [emailHasError, setEmailHasError] = useState(false);
  const [nameHasError, setNameHasError] = useState(false);
  const [messageHasError, setMessageHasError] = useState(false);

  const changeName = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      fullName: name,
    }));

    // Name validation
    if (!name.trim()) {
      setNameHasError(true);
    } else {
      setNameHasError(false);
    }
  };

  const changeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      email: email,
    }));

    // Email validation
    if (!email.trim()) {
      setEmailHasError(true);
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailHasError(true);
    } else {
      setEmailHasError(false);
    }
  };

  const changeMessage = (event: ChangeEvent<HTMLInputElement>) => {
    const message = event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      message: message,
    }));

    // Message validation
    if (!message.trim()) {
      setMessageHasError(true);
    } else {
      setMessageHasError(false);
    }
  };

  const disableSend =
    formData.fullName === '' ||
    formData.email === '' ||
    formData.message === '' ||
    emailHasError ||
    nameHasError ||
    messageHasError;

  if (state.succeeded) {
    return <ThankYouPage />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={formData.fullName}
            id="name"
            name="name"
            label="Full Name"
            variant="outlined"
            fullWidth
            required
            onChange={changeName}
            error={nameHasError}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formData.email}
            id="email"
            name="email"
            label="Email Address"
            variant="outlined"
            type="email"
            fullWidth
            required
            onChange={changeEmail}
            error={emailHasError}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formData.message}
            name="message"
            id="message"
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            required
            onChange={changeMessage}
            error={messageHasError}
          />
        </Grid>
        <Grid item xs={12} textAlign="right">
          <Button
            type="submit"
            variant="contained"
            disabled={disableSend || state.submitting}
            sx={{
              '&.Mui-disabled': {
                background: '#eaeaea',
                color: '#c0c0c0',
              },
            }}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export { ContactForm };
