import { FC } from 'react';
// import { PageEntrance } from '../loaders';
import { Grid } from '@mui/material';
import { PortfolioFilter } from '../components/PorfolioFilter';
import { ProjectDetails } from '../components/ProjectDetails';

const Projects: FC = () => {
  return (
    // <PageEntrance title="projects">
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <PortfolioFilter />
      </Grid>
      <ProjectDetails />
    </Grid>
    // </PageEntrance>
  );
};

export default Projects;
