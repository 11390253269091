import { Tooltip } from '@mui/material';
import { FC } from 'react';

interface TechLogoProps {
  name: string;
}

const TechLogo: FC<TechLogoProps> = ({ name }) => {
  const getLogo = () => {
    switch (name) {
      case 'React':
        return {
          src: 'react/react-original.svg',
          alt: 'react-icon',
        };
      case 'Redux':
        return {
          src: 'redux/redux-original.svg',
          alt: 'redux-icon',
        };
      case 'TypeScript':
        return {
          src: 'typescript/typescript-original.svg',
          alt: 'typescript-icon',
        };
      case 'JavaScript':
        return {
          src: 'javascript/javascript-original.svg',
          alt: 'javascript-icon',
        };
      case 'MaterialUI':
        return {
          src: 'materialui/materialui-original.svg',
          alt: 'material-ui-icon',
        };
      case 'Node':
        return {
          src: 'nodejs/nodejs-original.svg',
          alt: 'node-icon',
        };
      case 'Express':
        return {
          src: 'express/express-original.svg',
          alt: 'express-icon',
        };
      case 'HTML':
        return {
          src: 'html5/html5-original.svg',
          alt: 'html-icon',
        };
      case 'CSS':
        return {
          src: 'css3/css3-original.svg',
          alt: 'css-icon',
        };
      case 'WordPress':
        return {
          src: 'wordpress/wordpress-plain.svg',
          alt: 'wordpress-icon',
        };
      case 'PHP':
        return {
          src: 'php/php-original.svg',
          alt: 'php-icon',
        };
      case 'jQuery':
        return {
          src: 'jquery/jquery-original.svg',
          alt: 'jquery-icon',
        };
      case 'MongoDB':
        return {
          src: 'mongodb/mongodb-plain.svg',
          alt: 'mongodb-icon',
        };
      case 'Google Cloud':
        return {
          src: 'googlecloud/googlecloud-original.svg',
          alt: 'googlecloud-icon',
        };
      case 'Firebase':
        return {
          src: 'firebase/firebase-plain.svg',
          alt: 'firebase-icon',
        };
      case 'Storybook':
        return {
          src: 'storybook/storybook-original.svg',
          alt: 'storybook-icon',
        };
      case 'Jest':
        return {
          src: 'jest/jest-plain.svg',
          alt: 'jest-icon',
        };
      case 'Digital Ocean':
        return {
          src: 'digitalocean/digitalocean-original.svg',
          alt: 'digitalocean-icon',
        };
      case 'Linux':
        return {
          src: 'linux/linux-original.svg',
          alt: 'linux-icon',
        };
      case 'SQL':
        return {
          src: 'mysql/mysql-original.svg',
          alt: 'sql-icon',
        };
      case 'Woocommerce':
        return {
          src: 'woocommerce/woocommerce-original.svg',
          alt: 'woocommerce-icon',
        };
      case 'NextJS':
        return {
          src: 'nextjs/nextjs-original.svg',
          alt: 'nextjs-icon',
        };
      default:
        return {
          src: 'javascript/javascript-original.svg',
          alt: 'missing-icon',
        };
    }
  };

  const logo = getLogo();

  return (
    <Tooltip title={name} placement="top">
      <img
        src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${logo?.src}`}
        alt={logo?.alt}
        height="20px"
        width="20px"
        style={{ marginRight: '5px' }}
      />
    </Tooltip>
  );
};

export default TechLogo;
