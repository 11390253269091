import { Box, Card, Grid, Grow, Typography } from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavigationCardProps {
  title: string;
  link: string;
  delay: number;
}

const NavigationCard: FC<NavigationCardProps> = ({ title, link, delay }) => {
  const navigate = useNavigate();

  const { pathname: current } = useLocation();

  const handleNavigate = () => {
    navigate(link);
  };

  return (
    <Grow in={true} timeout={delay}>
      <Box onClick={handleNavigate}>
        <Card
          sx={{
            cursor: 'pointer',
            pointerEvents:
              title.toLowerCase() === current.slice(1) ? 'none' : 'auto',
            background:
              title.toLowerCase() === current.slice(1) ? '#9A3E96' : 'white',
            border: '2px solid transparent',
            '&:hover': {
              border: '2px solid #9A3E96',
              color:
                title.toLowerCase() !== current.slice(1) ? '#9A3E96' : 'white',
            },
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ padding: { md: 2, xs: 1 } }}
          >
            <Grid item xs={12}>
              <Typography variant="h3" sx={{ textAlign: 'center' }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Grow>
  );
};

export { NavigationCard };
