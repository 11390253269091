import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import './rocket.css';

const Rocket: FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Box
          component="img"
          alt="coming-soon"
          src="/icons/rocket.svg"
          id="rocket"
          sx={{ pointerEvents: 'none' }}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Box
          component={'img'}
          src="/images/moon-min.png"
          alt={'my-face'}
          sx={{
            height: { md: '800px', sm: '600px', xs: '400px' },
            width: { md: '800px', sm: '600px', xs: '400px' },
            marginTop: { md: '200px', xs: '100px' },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Rocket;
