// pages
import { FC } from 'react';
import Home from './pages/Home';
import Skills from './pages/Skills';
import Experience from './pages/Experience';
import Projects from './pages/Projects';
import Contact from './pages/Contact';

// other

// interface
interface Route {
  key: string;
  title: string;
  path: string;
  component: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: 'home-route',
    path: '/',
    title: 'Home',
    component: Home,
  },
  {
    key: 'experience-route',
    path: '/experience',
    title: 'Experience',
    component: Experience,
  },
  {
    key: 'projects-route',
    path: '/projects',
    title: 'Projects',
    component: Projects,
  },
  {
    key: 'skills-route',
    path: '/skills',
    title: 'Skills',
    component: Skills,
  },
  {
    key: 'contact-route',
    title: 'Contact',
    path: '/contact',
    component: Contact,
  },
];
