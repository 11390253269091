import { AppBar, Box, Grid, Toolbar, Typography, Zoom } from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const Header: FC = () => {
  const { pathname: current } = useLocation();
  const navigate = useNavigate();

  const hideContact = current.slice(1) === 'contact';

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleContact = () => {
    navigate('/contact');
  };

  return (
    <Box sx={{ flexGrow: 1, alignItems: 'center' }}>
      <AppBar
        position="static"
        sx={{ background: 'transparent' }}
        elevation={0}
      >
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={8}
              md={8}
              onClick={handleLogoClick}
              sx={{ cursor: 'pointer ', display: 'flex', alignItems: 'center' }}
            >
              <Box
                component="img"
                src="/images/logo.png"
                sx={{ width: 40, height: 40 }}
              />
              <Typography variant="h2" component="div" sx={{ ml: 1 }}>
                Zac Salazar
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              sx={{ textAlign: 'right' }}
              alignItems="center"
            >
              <Zoom in={!hideContact}>
                <EmailIcon sx={{ cursor: 'pointer' }} onClick={handleContact} />
              </Zoom>

              <a
                href="https://github.com/zackenichi"
                target="_blank"
                rel="noreferrer"
              >
                <GitHubIcon />
              </a>
              <a
                href=" https://www.linkedin.com/in/zac-salazar"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export { Header };
