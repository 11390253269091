import { Grid } from '@mui/material';
import { FC } from 'react';
import { NavigationCard } from './NavigationCard';
import { routes as appRoutes } from '../../routes';

const Navigation: FC = () => {
  const mainRoute = appRoutes.filter(
    (route) => route.key !== 'home-route' && route.key !== 'contact-route'
  );

  return (
    <Grid container spacing={1}>
      {mainRoute.map((route, index) => (
        <Grid key={route.key} item md={12} xs={4}>
          <NavigationCard
            title={route.title}
            link={route.path}
            delay={200 + index * 250}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export { Navigation };
