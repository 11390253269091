import { FC, useEffect } from 'react';
import { PropsWithChildren } from '../resources/interfaces/PropsWithChildren';
import { Box } from '@mui/material';
import Rocket from './Rocket';

import './stars.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setIsLoading } from '../store/reducers';

const StarsBG: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.ui.isLoading);

  // simulate loading
  useEffect(() => {
    setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 3000);
  }, [dispatch]);

  return (
    <Box
      sx={{
        height: '100vh',
        background:
          'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)',
      }}
      overflow="hidden"
    >
      <Box
        id="stars"
        sx={{
          width: '1px',
          height: '1px',
          background: 'transparent',
          boxShadow: `871px 14154px #fff, 602px 947px #fff, 758px 1044px #fff,
        943px 1563px #fff, 1011px 1843px #fff, 1824px 1693px #fff, 739px 439px #fff,
        1515px 143px #fff, 1979px 1281px #fff, 211px 717px #fff, 579px 1925px #fff,
        107px 156px #fff, 91px 52px #fff, 996px 323px #fff, 1669px 671px #fff,
        96px 294px #fff, 1679px 708px #fff, 1534px 1522px #fff, 1164px 1464px #fff,
        427px 1585px #fff, 354px 434px #fff, 148px 1104px #fff, 485px 1464px #fff,
        1166px 263px #fff, 54px 604px #fff, 1333px 1580px #fff, 1556px 347px #fff,
        1688px 267px #fff, 833px 1148px #fff, 663px 1615px #fff, 573px 154px #fff,
        821px 188px #fff, 41px 1688px #fff, 294px 736px #fff, 820px 1297px #fff,
        1866px 129px #fff, 728px 1190px #fff, 196px 796px #fff, 155px 457px #fff,
        678px 607px #fff, 597px 661px #fff, 23px 1768px #fff, 1804px 1217px #fff,
        490px 1680px #fff, 567px 1193px #fff, 428px 398px #fff, 993px 211px #fff,
        1784px 1892px #fff, 642px 1401px #fff, 234px 530px #fff, 164px 1451px #fff,
        40px 1991px #fff, 1128px 409px #fff, 1913px 952px #fff, 425px 848px #fff,
        714px 152px #fff, 1478px 871px #fff, 1139px 100px #fff, 1480px 124px #fff,
        1427px 613px #fff, 1030px 239px #fff, 389px 1697px #fff, 1718px 1129px #fff,
        1699px 756px #fff, 487px 927px #fff, 1812px 478px #fff, 222px 1422px #fff,
        214px 664px #fff, 199px 126px #fff, 219px 1405px #fff, 379px 498px #fff,
        874px 1215px #fff, 696px 1145px #fff, 1601px 1024px #fff, 1672px 1585px #fff,
        756px 1012px #fff, 1271px 355px #fff, 1494px 1720px #fff, 821px 993px #fff,
        1760px 1883px #fff, 635px 1754px #fff, 612px 1745px #fff, 1881px 1661px #fff,
        1963px 1639px #fff, 1557px 191px #fff, 1200px 1224px #fff,
        1597px 1587px #fff, 63px 719px #fff, 994px 1072px #fff, 1966px 1782px #fff,
        443px 193px #fff, 1078px 805px #fff, 1378px 228px #fff, 1137px 858px #fff,
        1826px 1342px #fff, 432px 831px #fff, 1061px 413px #fff, 1163px 57px #fff,
        642px 909px #fff, 1525px 1660px #fff, 66px 1468px #fff, 1612px 1503px #fff,
        1885px 1559px #fff, 948px 1833px #fff, 1755px 1565px #fff, 152px 1181px #fff,
        342px 883px #fff, 1437px 1136px #fff, 210px 1967px #fff, 1310px 1975px #fff,
        765px 1366px #fff, 55px 1112px #fff, 1047px 425px #fff, 350px 599px #fff,
        836px 1772px #fff, 159px 1714px #fff, 1043px 250px #fff, 388px 1427px #fff,
        465px 331px #fff, 1264px 1076px #fff, 36px 608px #fff, 368px 1068px #fff,
        1437px 1082px #fff, 1488px 1274px #fff, 310px 871px #fff, 342px 1268px #fff,
        219px 1368px #fff, 1148px 51px #fff, 992px 1987px #fff, 167px 711px #fff,
        1077px 895px #fff, 561px 434px #fff, 168px 513px #fff, 177px 1940px #fff,
        74px 1418px #fff, 1012px 1962px #fff, 1226px 789px #fff, 1616px 554px #fff,
        1658px 1290px #fff, 102px 1320px #fff, 192px 857px #fff, 1799px 1632px #fff,
        1363px 1007px #fff, 752px 1917px #fff, 1014px 291px #fff, 1670px 1413px #fff,
        273px 676px #fff, 473px 1958px #fff, 705px 1355px #fff, 1706px 508px #fff,
        1684px 213px #fff, 1244px 1720px #fff, 1087px 1527px #fff, 889px 36px #fff,
        1914px 746px #fff, 45px 1786px #fff, 739px 1954px #fff, 168px 482px #fff,
        1757px 1500px #fff, 733px 56px #fff, 229px 483px #fff, 1797px 1738px #fff,
        48px 104px #fff, 1025px 1023px #fff, 476px 1905px #fff, 1808px 29px #fff,
        1200px 197px #fff, 1289px 63px #fff, 944px 1611px #fff, 144px 370px #fff,
        893px 1755px #fff, 355px 1018px #fff, 1762px 928px #fff, 1672px 536px #fff,
        1755px 496px #fff, 473px 579px #fff, 896px 619px #fff, 286px 652px #fff,
        1964px 46px #fff, 1772px 991px #fff, 843px 1164px #fff, 1063px 726px #fff,
        218px 1354px #fff, 1929px 110px #fff, 275px 1296px #fff, 9px 1778px #fff,
        409px 1030px #fff, 655px 1543px #fff, 125px 1368px #fff, 1454px 97px #fff,
        711px 1841px #fff, 51px 1275px #fff, 221px 1588px #fff, 1645px 546px #fff,
        1750px 1735px #fff, 1509px 757px #fff, 1348px 1504px #fff, 843px 334px #fff,
        1042px 836px #fff, 745px 1870px #fff, 288px 1329px #fff, 318px 330px #fff,
        1099px 1620px #fff, 370px 51px #fff, 587px 1064px #fff, 1814px 440px #fff,
        403px 1562px #fff, 1421px 121px #fff, 200px 162px #fff, 557px 15px #fff,
        1295px 623px #fff, 1252px 65px #fff, 1607px 1523px #fff, 1453px 1410px #fff,
        513px 672px #fff, 1769px 854px #fff, 1604px 1989px #fff, 663px 1021px #fff,
        538px 854px #fff, 376px 1064px #fff, 801px 1936px #fff, 9px 1450px #fff,
        619px 155px #fff, 905px 404px #fff, 1835px 1320px #fff, 210px 518px #fff,
        1232px 1265px #fff, 676px 354px #fff, 738px 1158px #fff, 20px 411px #fff,
        1101px 1133px #fff, 1635px 738px #fff, 1773px 259px #fff, 109px 502px #fff,
        345px 1211px #fff, 874px 968px #fff, 1574px 1149px #fff, 1052px 1051px #fff,
        165px 536px #fff, 231px 1291px #fff, 962px 73px #fff, 131px 1163px #fff,
        1997px 924px #fff, 222px 362px #fff, 1334px 1440px #fff, 1934px 339px #fff,
        107px 977px #fff, 62px 1718px #fff, 768px 1801px #fff, 1480px 1807px #fff,
        1459px 1048px #fff, 1090px 1px #fff, 1642px 1245px #fff, 206px 90px #fff,
        671px 466px #fff, 161px 975px #fff, 383px 397px #fff, 1361px 813px #fff,
        35px 6px #fff, 968px 1802px #fff, 650px 1201px #fff, 1878px 1993px #fff,
        427px 1159px #fff, 851px 1098px #fff, 85px 1860px #fff, 1373px 1918px #fff,
        1689px 1498px #fff, 1256px 1963px #fff, 1799px 203px #fff,
        1780px 1983px #fff, 329px 1440px #fff, 1917px 1338px #fff, 179px 579px #fff,
        1444px 963px #fff, 1956px 1249px #fff, 549px 1929px #fff, 959px 301px #fff,
        343px 926px #fff, 1275px 1098px #fff, 1727px 794px #fff, 1101px 943px #fff,
        786px 1560px #fff, 1840px 1797px #fff, 1253px 1451px #fff, 1334px 102px #fff,
        1939px 1499px #fff, 120px 1939px #fff, 550px 142px #fff, 1367px 1938px #fff,
        1277px 758px #fff, 23px 1355px #fff, 784px 682px #fff, 469px 1160px #fff,
        706px 1147px #fff, 631px 1724px #fff, 1026px 1611px #fff, 918px 1274px #fff,
        1476px 1700px #fff, 1793px 209px #fff, 557px 616px #fff, 894px 1648px #fff,
        240px 65px #fff, 388px 417px #fff, 1747px 442px #fff, 312px 802px #fff,
        875px 852px #fff, 1348px 335px #fff, 1624px 1662px #fff, 684px 967px #fff,
        15px 593px #fff, 990px 1463px #fff, 1908px 44px #fff, 1062px 1598px #fff,
        854px 1932px #fff, 1922px 380px #fff, 788px 1234px #fff, 1085px 808px #fff,
        705px 1859px #fff, 1024px 1520px #fff, 1376px 13px #fff, 1534px 1089px #fff,
        126px 580px #fff, 43px 344px #fff, 710px 579px #fff, 273px 256px #fff,
        873px 902px #fff, 1281px 1115px #fff, 1614px 507px #fff, 1498px 217px #fff,
        812px 866px #fff, 807px 1827px #fff, 168px 1095px #fff, 1342px 810px #fff,
        694px 830px #fff, 1890px 1709px #fff, 1286px 133px #fff, 1586px 1052px #fff,
        1209px 864px #fff, 175px 718px #fff, 1517px 518px #fff, 1557px 84px #fff,
        338px 462px #fff, 179px 803px #fff, 568px 402px #fff, 1227px 284px #fff,
        144px 705px #fff, 545px 1360px #fff, 559px 207px #fff, 1000px 1214px #fff,
        1910px 1075px #fff, 1349px 1181px #fff, 583px 275px #fff, 499px 99px #fff,
        1566px 265px #fff, 1994px 1523px #fff, 1364px 1646px #fff, 1753px 913px #fff,
        613px 1566px #fff, 1594px 1031px #fff, 1598px 780px #fff, 1334px 1952px #fff,
        1212px 809px #fff, 1732px 1176px #fff, 1423px 894px #fff, 96px 1964px #fff,
        953px 1519px #fff, 1443px 1502px #fff, 1887px 1692px #fff, 632px 998px #fff,
        950px 1850px #fff, 223px 406px #fff, 1803px 1368px #fff, 671px 34px #fff,
        915px 603px #fff, 1715px 701px #fff, 417px 1994px #fff, 1857px 863px #fff,
        174px 470px #fff, 1394px 993px #fff, 911px 1397px #fff, 1834px 905px #fff,
        546px 1778px #fff, 188px 1169px #fff, 770px 194px #fff, 1750px 1130px #fff,
        1400px 155px #fff, 661px 1805px #fff, 1718px 750px #fff, 1744px 431px #fff,
        376px 736px #fff, 905px 423px #fff, 872px 692px #fff, 518px 431px #fff,
        463px 696px #fff, 1068px 1477px #fff, 1591px 1570px #fff, 900px 1974px #fff,
        1727px 388px #fff, 1870px 1960px #fff, 142px 742px #fff, 1467px 1846px #fff,
        349px 873px #fff, 858px 88px #fff, 1492px 620px #fff, 1364px 1269px #fff,
        109px 532px #fff, 745px 1728px #fff, 16px 604px #fff, 1176px 1576px #fff,
        724px 1468px #fff, 1866px 1166px #fff, 1259px 1794px #fff, 695px 1669px #fff,
        1012px 787px #fff, 585px 1991px #fff, 1708px 108px #fff, 1821px 386px #fff,
        1489px 1027px #fff, 505px 923px #fff, 763px 152px #fff, 722px 827px #fff,
        474px 1780px #fff, 308px 1882px #fff, 596px 562px #fff, 1357px 784px #fff,
        1875px 1578px #fff, 1431px 395px #fff, 81px 1649px #fff, 1462px 1792px #fff,
        311px 273px #fff, 1192px 1618px #fff, 1854px 688px #fff, 1522px 463px #fff,
        478px 970px #fff, 884px 1787px #fff, 498px 803px #fff, 13px 1125px #fff,
        837px 1086px #fff, 1619px 1866px #fff, 1285px 459px #fff, 692px 872px #fff,
        1791px 1277px #fff, 1294px 784px #fff, 1597px 456px #fff, 1980px 1915px #fff,
        1053px 1782px #fff, 780px 1515px #fff, 1223px 1373px #fff, 506px 1219px #fff,
        588px 849px #fff, 978px 1677px #fff, 1661px 1981px #fff, 1557px 1861px #fff,
        24px 1449px #fff, 713px 42px #fff, 1463px 188px #fff, 1461px 152px #fff,
        508px 1589px #fff, 629px 509px #fff, 1197px 328px #fff, 1301px 75px #fff,
        1435px 46px #fff, 431px 752px #fff, 1214px 1392px #fff, 973px 1466px #fff,
        1367px 1529px #fff, 1328px 1678px #fff, 1551px 1823px #fff,
        185px 1117px #fff, 296px 1654px #fff, 1138px 1874px #fff, 1439px 905px #fff,
        419px 831px #fff, 21px 1557px #fff, 988px 272px #fff, 1068px 34px #fff,
        430px 84px #fff, 1651px 790px #fff, 1685px 666px #fff, 354px 899px #fff,
        1326px 1793px #fff, 1848px 1985px #fff, 448px 1531px #fff, 1398px 159px #fff,
        1627px 1738px #fff, 1660px 740px #fff, 1721px 278px #fff, 575px 1269px #fff,
        143px 366px #fff, 752px 1097px #fff, 1811px 396px #fff, 981px 653px #fff,
        776px 1404px #fff, 1029px 905px #fff, 188px 1806px #fff, 1078px 195px #fff,
        539px 765px #fff, 1239px 336px #fff, 806px 819px #fff, 1728px 674px #fff,
        1880px 491px #fff, 864px 477px #fff, 842px 1007px #fff, 96px 102px #fff,
        987px 76px #fff, 301px 1100px #fff, 1606px 638px #fff, 769px 1120px #fff,
        996px 419px #fff, 982px 1395px #fff, 1992px 1191px #fff, 275px 1402px #fff,
        922px 1307px #fff, 815px 577px #fff, 1573px 1978px #fff, 203px 1609px #fff,
        665px 626px #fff, 924px 92px #fff, 120px 298px #fff, 1559px 1773px #fff,
        1856px 1252px #fff, 576px 743px #fff, 1663px 1314px #fff, 1111px 182px #fff,
        838px 81px #fff, 948px 1374px #fff, 925px 441px #fff, 1568px 1202px #fff,
        751px 429px #fff, 479px 1794px #fff, 99px 1675px #fff, 1864px 1769px #fff,
        860px 663px #fff, 1909px 491px #fff, 58px 1216px #fff, 1119px 839px #fff,
        1223px 1183px #fff, 1065px 1229px #fff, 1242px 1320px #fff,
        1140px 112px #fff, 765px 864px #fff, 682px 480px #fff, 298px 264px #fff,
        295px 261px #fff, 1095px 1225px #fff, 1388px 643px #fff, 1535px 284px #fff,
        1726px 81px #fff, 1641px 1085px #fff, 967px 1101px #fff, 293px 1702px #fff,
        1553px 319px #fff, 1260px 1228px #fff, 871px 235px #fff, 1927px 1443px #fff,
        980px 1297px #fff, 647px 1987px #fff, 659px 1992px #fff, 1365px 1479px #fff,
        1685px 1664px #fff, 1574px 1979px #fff, 1500px 574px #fff, 1427px 728px #fff,
        1916px 420px #fff, 1045px 277px #fff, 444px 628px #fff, 1275px 88px #fff,
        684px 1951px #fff, 21px 1939px #fff, 29px 424px #fff, 1648px 1542px #fff,
        40px 1460px #fff, 574px 22px #fff, 1413px 1279px #fff, 1756px 1213px #fff,
        728px 1503px #fff, 1983px 1662px #fff, 953px 383px #fff, 120px 1129px #fff,
        423px 1187px #fff, 341px 1983px #fff, 425px 540px #fff, 654px 789px #fff,
        357px 1447px #fff, 519px 872px #fff, 1533px 778px #fff, 582px 244px #fff,
        904px 1545px #fff, 54px 1665px #fff, 958px 493px #fff, 1991px 342px #fff,
        1424px 1383px #fff, 898px 803px #fff, 638px 1783px #fff, 1208px 814px #fff,
        476px 656px #fff, 394px 1678px #fff, 112px 1977px #fff, 1675px 1354px #fff,
        1484px 1040px #fff, 1505px 1034px #fff, 1675px 699px #fff, 228px 41px #fff,
        20px 416px #fff, 906px 1942px #fff, 92px 1053px #fff, 1722px 1051px #fff,
        70px 1062px #fff, 705px 1133px #fff, 1693px 1864px #fff, 1861px 1338px #fff,
        229px 442px #fff, 510px 1522px #fff, 157px 810px #fff, 911px 38px #fff,
        1655px 119px #fff, 1911px 1101px #fff, 491px 739px #fff, 912px 372px #fff,
        1297px 744px #fff, 1041px 1982px #fff, 544px 660px #fff, 1100px 1913px #fff,
        1978px 30px #fff, 1249px 1789px #fff, 1610px 394px #fff, 1691px 640px #fff,
        716px 337px #fff, 1882px 1780px #fff, 1513px 1418px #fff, 1292px 1884px #fff,
        914px 1976px #fff, 1710px 1857px #fff, 1384px 1546px #fff,
        1180px 1292px #fff, 1750px 793px #fff, 1409px 319px #fff, 96px 390px #fff,
        1764px 41px #fff, 1162px 1473px #fff, 1571px 234px #fff, 1036px 404px #fff,
        461px 836px #fff, 246px 1639px #fff, 1468px 1670px #fff, 1696px 1293px #fff,
        18px 1971px #fff, 665px 1831px #fff, 1746px 264px #fff, 1430px 74px #fff,
        554px 734px #fff, 294px 1731px #fff, 1269px 378px #fff, 405px 1931px #fff,
        47px 1737px #fff, 204px 900px #fff, 355px 1198px #fff, 1667px 1006px #fff,
        920px 672px #fff, 1295px 1760px #fff, 961px 1428px #fff, 1739px 362px #fff,
        1255px 510px #fff, 1681px 1648px #fff, 1246px 1506px #fff, 1012px 692px #fff,
        229px 1999px #fff, 412px 746px #fff, 1834px 159px #fff, 622px 1445px #fff,
        203px 1582px #fff, 1708px 158px #fff, 1128px 506px #fff, 508px 138px #fff,
        534px 57px #fff, 1181px 1658px #fff, 969px 1554px #fff, 1966px 1240px #fff,
        12px 131px #fff, 11px 394px #fff, 1886px 1537px #fff, 1423px 82px #fff,
        945px 1546px #fff, 373px 1246px #fff, 1229px 1242px #fff, 1717px 1992px #fff,
        178px 495px #fff, 1148px 1602px #fff, 659px 1232px #fff, 191px 130px #fff,
        1739px 951px #fff, 582px 1376px #fff, 1906px 29px #fff, 1301px 1198px #fff,
        1643px 1261px #fff, 59px 471px #fff, 1711px 1470px #fff, 1452px 1211px #fff,
        624px 848px #fff, 988px 1479px #fff`,
          '&:after': {
            boxShadow: `871px 14154px #fff, 602px 947px #fff, 758px 1044px #fff,
          943px 1563px #fff, 1011px 1843px #fff, 1824px 1693px #fff, 739px 439px #fff,
          1515px 143px #fff, 1979px 1281px #fff, 211px 717px #fff, 579px 1925px #fff,
          107px 156px #fff, 91px 52px #fff, 996px 323px #fff, 1669px 671px #fff,
          96px 294px #fff, 1679px 708px #fff, 1534px 1522px #fff, 1164px 1464px #fff,
          427px 1585px #fff, 354px 434px #fff, 148px 1104px #fff, 485px 1464px #fff,
          1166px 263px #fff, 54px 604px #fff, 1333px 1580px #fff, 1556px 347px #fff,
          1688px 267px #fff, 833px 1148px #fff, 663px 1615px #fff, 573px 154px #fff,
          821px 188px #fff, 41px 1688px #fff, 294px 736px #fff, 820px 1297px #fff,
          1866px 129px #fff, 728px 1190px #fff, 196px 796px #fff, 155px 457px #fff,
          678px 607px #fff, 597px 661px #fff, 23px 1768px #fff, 1804px 1217px #fff,
          490px 1680px #fff, 567px 1193px #fff, 428px 398px #fff, 993px 211px #fff,
          1784px 1892px #fff, 642px 1401px #fff, 234px 530px #fff, 164px 1451px #fff,
          40px 1991px #fff, 1128px 409px #fff, 1913px 952px #fff, 425px 848px #fff,
          714px 152px #fff, 1478px 871px #fff, 1139px 100px #fff, 1480px 124px #fff,
          1427px 613px #fff, 1030px 239px #fff, 389px 1697px #fff, 1718px 1129px #fff,
          1699px 756px #fff, 487px 927px #fff, 1812px 478px #fff, 222px 1422px #fff,
          214px 664px #fff, 199px 126px #fff, 219px 1405px #fff, 379px 498px #fff,
          874px 1215px #fff, 696px 1145px #fff, 1601px 1024px #fff, 1672px 1585px #fff,
          756px 1012px #fff, 1271px 355px #fff, 1494px 1720px #fff, 821px 993px #fff,
          1760px 1883px #fff, 635px 1754px #fff, 612px 1745px #fff, 1881px 1661px #fff,
          1963px 1639px #fff, 1557px 191px #fff, 1200px 1224px #fff,
          1597px 1587px #fff, 63px 719px #fff, 994px 1072px #fff, 1966px 1782px #fff,
          443px 193px #fff, 1078px 805px #fff, 1378px 228px #fff, 1137px 858px #fff,
          1826px 1342px #fff, 432px 831px #fff, 1061px 413px #fff, 1163px 57px #fff,
          642px 909px #fff, 1525px 1660px #fff, 66px 1468px #fff, 1612px 1503px #fff,
          1885px 1559px #fff, 948px 1833px #fff, 1755px 1565px #fff, 152px 1181px #fff,
          342px 883px #fff, 1437px 1136px #fff, 210px 1967px #fff, 1310px 1975px #fff,
          765px 1366px #fff, 55px 1112px #fff, 1047px 425px #fff, 350px 599px #fff,
          836px 1772px #fff, 159px 1714px #fff, 1043px 250px #fff, 388px 1427px #fff,
          465px 331px #fff, 1264px 1076px #fff, 36px 608px #fff, 368px 1068px #fff,
          1437px 1082px #fff, 1488px 1274px #fff, 310px 871px #fff, 342px 1268px #fff,
          219px 1368px #fff, 1148px 51px #fff, 992px 1987px #fff, 167px 711px #fff,
          1077px 895px #fff, 561px 434px #fff, 168px 513px #fff, 177px 1940px #fff,
          74px 1418px #fff, 1012px 1962px #fff, 1226px 789px #fff, 1616px 554px #fff,
          1658px 1290px #fff, 102px 1320px #fff, 192px 857px #fff, 1799px 1632px #fff,
          1363px 1007px #fff, 752px 1917px #fff, 1014px 291px #fff, 1670px 1413px #fff,
          273px 676px #fff, 473px 1958px #fff, 705px 1355px #fff, 1706px 508px #fff,
          1684px 213px #fff, 1244px 1720px #fff, 1087px 1527px #fff, 889px 36px #fff,
          1914px 746px #fff, 45px 1786px #fff, 739px 1954px #fff, 168px 482px #fff,
          1757px 1500px #fff, 733px 56px #fff, 229px 483px #fff, 1797px 1738px #fff,
          48px 104px #fff, 1025px 1023px #fff, 476px 1905px #fff, 1808px 29px #fff,
          1200px 197px #fff, 1289px 63px #fff, 944px 1611px #fff, 144px 370px #fff,
          893px 1755px #fff, 355px 1018px #fff, 1762px 928px #fff, 1672px 536px #fff,
          1755px 496px #fff, 473px 579px #fff, 896px 619px #fff, 286px 652px #fff,
          1964px 46px #fff, 1772px 991px #fff, 843px 1164px #fff, 1063px 726px #fff,
          218px 1354px #fff, 1929px 110px #fff, 275px 1296px #fff, 9px 1778px #fff,
          409px 1030px #fff, 655px 1543px #fff, 125px 1368px #fff, 1454px 97px #fff,
          711px 1841px #fff, 51px 1275px #fff, 221px 1588px #fff, 1645px 546px #fff,
          1750px 1735px #fff, 1509px 757px #fff, 1348px 1504px #fff, 843px 334px #fff,
          1042px 836px #fff, 745px 1870px #fff, 288px 1329px #fff, 318px 330px #fff,
          1099px 1620px #fff, 370px 51px #fff, 587px 1064px #fff, 1814px 440px #fff,
          403px 1562px #fff, 1421px 121px #fff, 200px 162px #fff, 557px 15px #fff,
          1295px 623px #fff, 1252px 65px #fff, 1607px 1523px #fff, 1453px 1410px #fff,
          513px 672px #fff, 1769px 854px #fff, 1604px 1989px #fff, 663px 1021px #fff,
          538px 854px #fff, 376px 1064px #fff, 801px 1936px #fff, 9px 1450px #fff,
          619px 155px #fff, 905px 404px #fff, 1835px 1320px #fff, 210px 518px #fff,
          1232px 1265px #fff, 676px 354px #fff, 738px 1158px #fff, 20px 411px #fff,
          1101px 1133px #fff, 1635px 738px #fff, 1773px 259px #fff, 109px 502px #fff,
          345px 1211px #fff, 874px 968px #fff, 1574px 1149px #fff, 1052px 1051px #fff,
          165px 536px #fff, 231px 1291px #fff, 962px 73px #fff, 131px 1163px #fff,
          1997px 924px #fff, 222px 362px #fff, 1334px 1440px #fff, 1934px 339px #fff,
          107px 977px #fff, 62px 1718px #fff, 768px 1801px #fff, 1480px 1807px #fff,
          1459px 1048px #fff, 1090px 1px #fff, 1642px 1245px #fff, 206px 90px #fff,
          671px 466px #fff, 161px 975px #fff, 383px 397px #fff, 1361px 813px #fff,
          35px 6px #fff, 968px 1802px #fff, 650px 1201px #fff, 1878px 1993px #fff,
          427px 1159px #fff, 851px 1098px #fff, 85px 1860px #fff, 1373px 1918px #fff,
          1689px 1498px #fff, 1256px 1963px #fff, 1799px 203px #fff,
          1780px 1983px #fff, 329px 1440px #fff, 1917px 1338px #fff, 179px 579px #fff,
          1444px 963px #fff, 1956px 1249px #fff, 549px 1929px #fff, 959px 301px #fff,
          343px 926px #fff, 1275px 1098px #fff, 1727px 794px #fff, 1101px 943px #fff,
          786px 1560px #fff, 1840px 1797px #fff, 1253px 1451px #fff, 1334px 102px #fff,
          1939px 1499px #fff, 120px 1939px #fff, 550px 142px #fff, 1367px 1938px #fff,
          1277px 758px #fff, 23px 1355px #fff, 784px 682px #fff, 469px 1160px #fff,
          706px 1147px #fff, 631px 1724px #fff, 1026px 1611px #fff, 918px 1274px #fff,
          1476px 1700px #fff, 1793px 209px #fff, 557px 616px #fff, 894px 1648px #fff,
          240px 65px #fff, 388px 417px #fff, 1747px 442px #fff, 312px 802px #fff,
          875px 852px #fff, 1348px 335px #fff, 1624px 1662px #fff, 684px 967px #fff,
          15px 593px #fff, 990px 1463px #fff, 1908px 44px #fff, 1062px 1598px #fff,
          854px 1932px #fff, 1922px 380px #fff, 788px 1234px #fff, 1085px 808px #fff,
          705px 1859px #fff, 1024px 1520px #fff, 1376px 13px #fff, 1534px 1089px #fff,
          126px 580px #fff, 43px 344px #fff, 710px 579px #fff, 273px 256px #fff,
          873px 902px #fff, 1281px 1115px #fff, 1614px 507px #fff, 1498px 217px #fff,
          812px 866px #fff, 807px 1827px #fff, 168px 1095px #fff, 1342px 810px #fff,
          694px 830px #fff, 1890px 1709px #fff, 1286px 133px #fff, 1586px 1052px #fff,
          1209px 864px #fff, 175px 718px #fff, 1517px 518px #fff, 1557px 84px #fff,
          338px 462px #fff, 179px 803px #fff, 568px 402px #fff, 1227px 284px #fff,
          144px 705px #fff, 545px 1360px #fff, 559px 207px #fff, 1000px 1214px #fff,
          1910px 1075px #fff, 1349px 1181px #fff, 583px 275px #fff, 499px 99px #fff,
          1566px 265px #fff, 1994px 1523px #fff, 1364px 1646px #fff, 1753px 913px #fff,
          613px 1566px #fff, 1594px 1031px #fff, 1598px 780px #fff, 1334px 1952px #fff,
          1212px 809px #fff, 1732px 1176px #fff, 1423px 894px #fff, 96px 1964px #fff,
          953px 1519px #fff, 1443px 1502px #fff, 1887px 1692px #fff, 632px 998px #fff,
          950px 1850px #fff, 223px 406px #fff, 1803px 1368px #fff, 671px 34px #fff,
          915px 603px #fff, 1715px 701px #fff, 417px 1994px #fff, 1857px 863px #fff,
          174px 470px #fff, 1394px 993px #fff, 911px 1397px #fff, 1834px 905px #fff,
          546px 1778px #fff, 188px 1169px #fff, 770px 194px #fff, 1750px 1130px #fff,
          1400px 155px #fff, 661px 1805px #fff, 1718px 750px #fff, 1744px 431px #fff,
          376px 736px #fff, 905px 423px #fff, 872px 692px #fff, 518px 431px #fff,
          463px 696px #fff, 1068px 1477px #fff, 1591px 1570px #fff, 900px 1974px #fff,
          1727px 388px #fff, 1870px 1960px #fff, 142px 742px #fff, 1467px 1846px #fff,
          349px 873px #fff, 858px 88px #fff, 1492px 620px #fff, 1364px 1269px #fff,
          109px 532px #fff, 745px 1728px #fff, 16px 604px #fff, 1176px 1576px #fff,
          724px 1468px #fff, 1866px 1166px #fff, 1259px 1794px #fff, 695px 1669px #fff,
          1012px 787px #fff, 585px 1991px #fff, 1708px 108px #fff, 1821px 386px #fff,
          1489px 1027px #fff, 505px 923px #fff, 763px 152px #fff, 722px 827px #fff,
          474px 1780px #fff, 308px 1882px #fff, 596px 562px #fff, 1357px 784px #fff,
          1875px 1578px #fff, 1431px 395px #fff, 81px 1649px #fff, 1462px 1792px #fff,
          311px 273px #fff, 1192px 1618px #fff, 1854px 688px #fff, 1522px 463px #fff,
          478px 970px #fff, 884px 1787px #fff, 498px 803px #fff, 13px 1125px #fff,
          837px 1086px #fff, 1619px 1866px #fff, 1285px 459px #fff, 692px 872px #fff,
          1791px 1277px #fff, 1294px 784px #fff, 1597px 456px #fff, 1980px 1915px #fff,
          1053px 1782px #fff, 780px 1515px #fff, 1223px 1373px #fff, 506px 1219px #fff,
          588px 849px #fff, 978px 1677px #fff, 1661px 1981px #fff, 1557px 1861px #fff,
          24px 1449px #fff, 713px 42px #fff, 1463px 188px #fff, 1461px 152px #fff,
          508px 1589px #fff, 629px 509px #fff, 1197px 328px #fff, 1301px 75px #fff,
          1435px 46px #fff, 431px 752px #fff, 1214px 1392px #fff, 973px 1466px #fff,
          1367px 1529px #fff, 1328px 1678px #fff, 1551px 1823px #fff,
          185px 1117px #fff, 296px 1654px #fff, 1138px 1874px #fff, 1439px 905px #fff,
          419px 831px #fff, 21px 1557px #fff, 988px 272px #fff, 1068px 34px #fff,
          430px 84px #fff, 1651px 790px #fff, 1685px 666px #fff, 354px 899px #fff,
          1326px 1793px #fff, 1848px 1985px #fff, 448px 1531px #fff, 1398px 159px #fff,
          1627px 1738px #fff, 1660px 740px #fff, 1721px 278px #fff, 575px 1269px #fff,
          143px 366px #fff, 752px 1097px #fff, 1811px 396px #fff, 981px 653px #fff,
          776px 1404px #fff, 1029px 905px #fff, 188px 1806px #fff, 1078px 195px #fff,
          539px 765px #fff, 1239px 336px #fff, 806px 819px #fff, 1728px 674px #fff,
          1880px 491px #fff, 864px 477px #fff, 842px 1007px #fff, 96px 102px #fff,
          987px 76px #fff, 301px 1100px #fff, 1606px 638px #fff, 769px 1120px #fff,
          996px 419px #fff, 982px 1395px #fff, 1992px 1191px #fff, 275px 1402px #fff,
          922px 1307px #fff, 815px 577px #fff, 1573px 1978px #fff, 203px 1609px #fff,
          665px 626px #fff, 924px 92px #fff, 120px 298px #fff, 1559px 1773px #fff,
          1856px 1252px #fff, 576px 743px #fff, 1663px 1314px #fff, 1111px 182px #fff,
          838px 81px #fff, 948px 1374px #fff, 925px 441px #fff, 1568px 1202px #fff,
          751px 429px #fff, 479px 1794px #fff, 99px 1675px #fff, 1864px 1769px #fff,
          860px 663px #fff, 1909px 491px #fff, 58px 1216px #fff, 1119px 839px #fff,
          1223px 1183px #fff, 1065px 1229px #fff, 1242px 1320px #fff,
          1140px 112px #fff, 765px 864px #fff, 682px 480px #fff, 298px 264px #fff,
          295px 261px #fff, 1095px 1225px #fff, 1388px 643px #fff, 1535px 284px #fff,
          1726px 81px #fff, 1641px 1085px #fff, 967px 1101px #fff, 293px 1702px #fff,
          1553px 319px #fff, 1260px 1228px #fff, 871px 235px #fff, 1927px 1443px #fff,
          980px 1297px #fff, 647px 1987px #fff, 659px 1992px #fff, 1365px 1479px #fff,
          1685px 1664px #fff, 1574px 1979px #fff, 1500px 574px #fff, 1427px 728px #fff,
          1916px 420px #fff, 1045px 277px #fff, 444px 628px #fff, 1275px 88px #fff,
          684px 1951px #fff, 21px 1939px #fff, 29px 424px #fff, 1648px 1542px #fff,
          40px 1460px #fff, 574px 22px #fff, 1413px 1279px #fff, 1756px 1213px #fff,
          728px 1503px #fff, 1983px 1662px #fff, 953px 383px #fff, 120px 1129px #fff,
          423px 1187px #fff, 341px 1983px #fff, 425px 540px #fff, 654px 789px #fff,
          357px 1447px #fff, 519px 872px #fff, 1533px 778px #fff, 582px 244px #fff,
          904px 1545px #fff, 54px 1665px #fff, 958px 493px #fff, 1991px 342px #fff,
          1424px 1383px #fff, 898px 803px #fff, 638px 1783px #fff, 1208px 814px #fff,
          476px 656px #fff, 394px 1678px #fff, 112px 1977px #fff, 1675px 1354px #fff,
          1484px 1040px #fff, 1505px 1034px #fff, 1675px 699px #fff, 228px 41px #fff,
          20px 416px #fff, 906px 1942px #fff, 92px 1053px #fff, 1722px 1051px #fff,
          70px 1062px #fff, 705px 1133px #fff, 1693px 1864px #fff, 1861px 1338px #fff,
          229px 442px #fff, 510px 1522px #fff, 157px 810px #fff, 911px 38px #fff,
          1655px 119px #fff, 1911px 1101px #fff, 491px 739px #fff, 912px 372px #fff,
          1297px 744px #fff, 1041px 1982px #fff, 544px 660px #fff, 1100px 1913px #fff,
          1978px 30px #fff, 1249px 1789px #fff, 1610px 394px #fff, 1691px 640px #fff,
          716px 337px #fff, 1882px 1780px #fff, 1513px 1418px #fff, 1292px 1884px #fff,
          914px 1976px #fff, 1710px 1857px #fff, 1384px 1546px #fff,
          1180px 1292px #fff, 1750px 793px #fff, 1409px 319px #fff, 96px 390px #fff,
          1764px 41px #fff, 1162px 1473px #fff, 1571px 234px #fff, 1036px 404px #fff,
          461px 836px #fff, 246px 1639px #fff, 1468px 1670px #fff, 1696px 1293px #fff,
          18px 1971px #fff, 665px 1831px #fff, 1746px 264px #fff, 1430px 74px #fff,
          554px 734px #fff, 294px 1731px #fff, 1269px 378px #fff, 405px 1931px #fff,
          47px 1737px #fff, 204px 900px #fff, 355px 1198px #fff, 1667px 1006px #fff,
          920px 672px #fff, 1295px 1760px #fff, 961px 1428px #fff, 1739px 362px #fff,
          1255px 510px #fff, 1681px 1648px #fff, 1246px 1506px #fff, 1012px 692px #fff,
          229px 1999px #fff, 412px 746px #fff, 1834px 159px #fff, 622px 1445px #fff,
          203px 1582px #fff, 1708px 158px #fff, 1128px 506px #fff, 508px 138px #fff,
          534px 57px #fff, 1181px 1658px #fff, 969px 1554px #fff, 1966px 1240px #fff,
          12px 131px #fff, 11px 394px #fff, 1886px 1537px #fff, 1423px 82px #fff,
          945px 1546px #fff, 373px 1246px #fff, 1229px 1242px #fff, 1717px 1992px #fff,
          178px 495px #fff, 1148px 1602px #fff, 659px 1232px #fff, 191px 130px #fff,
          1739px 951px #fff, 582px 1376px #fff, 1906px 29px #fff, 1301px 1198px #fff,
          1643px 1261px #fff, 59px 471px #fff, 1711px 1470px #fff, 1452px 1211px #fff,
          624px 848px #fff, 988px 1479px #fff`,
          },
        }}
      />
      <Box
        id="stars2"
        sx={{
          width: '2px',
          height: '2px',
          background: 'transparent',
          boxShadow: `1774px 3429px #fff, 1487px 495px #fff, 1855px 613px #fff,
        526px 1860px #fff, 577px 1571px #fff, 686px 1802px #fff, 474px 165px #fff,
        13px 1313px #fff, 1022px 1217px #fff, 1199px 671px #fff, 1326px 1718px #fff,
        206px 1539px #fff, 1997px 1163px #fff, 1630px 1946px #fff, 441px 1649px #fff,
        1807px 1335px #fff, 396px 1319px #fff, 514px 1193px #fff, 669px 389px #fff,
        1980px 178px #fff, 977px 712px #fff, 1235px 1369px #fff, 1719px 558px #fff,
        401px 490px #fff, 440px 1270px #fff, 1548px 21px #fff, 313px 1188px #fff,
        1837px 1542px #fff, 709px 557px #fff, 697px 283px #fff, 278px 643px #fff,
        1650px 181px #fff, 169px 1610px #fff, 47px 272px #fff, 1747px 80px #fff,
        1239px 1730px #fff, 219px 64px #fff, 305px 1383px #fff, 1318px 1065px #fff,
        33px 1727px #fff, 1494px 21px #fff, 1745px 67px #fff, 327px 758px #fff,
        656px 1763px #fff, 192px 1413px #fff, 332px 425px #fff, 1095px 1000px #fff,
        1510px 1148px #fff, 1801px 1191px #fff, 1633px 1747px #fff,
        1756px 1842px #fff, 1948px 1726px #fff, 964px 1065px #fff, 1766px 759px #fff,
        343px 1175px #fff, 666px 107px #fff, 107px 1926px #fff, 637px 1405px #fff,
        1657px 1845px #fff, 1208px 1838px #fff, 1926px 1456px #fff,
        1710px 1641px #fff, 1194px 1817px #fff, 1377px 1408px #fff,
        1265px 878px #fff, 188px 1917px #fff, 1461px 1059px #fff, 1568px 361px #fff,
        1701px 887px #fff, 1667px 1263px #fff, 1316px 1670px #fff, 1145px 997px #fff,
        1855px 1831px #fff, 205px 1810px #fff, 223px 209px #fff, 1013px 708px #fff,
        1560px 1728px #fff, 144px 176px #fff, 716px 1187px #fff, 1168px 301px #fff,
        1754px 740px #fff, 981px 1581px #fff, 1323px 475px #fff, 793px 833px #fff,
        1752px 1344px #fff, 1463px 1011px #fff, 3px 457px #fff, 1435px 1437px #fff,
        550px 73px #fff, 396px 1369px #fff, 1383px 143px #fff, 151px 1177px #fff,
        820px 76px #fff, 665px 1907px #fff, 94px 1648px #fff, 762px 723px #fff,
        1604px 1208px #fff, 97px 1925px #fff, 675px 29px #fff, 1500px 1670px #fff,
        728px 560px #fff, 482px 1265px #fff, 1084px 1157px #fff, 553px 1104px #fff,
        1216px 1474px #fff, 747px 1341px #fff, 1370px 1615px #fff, 1572px 364px #fff,
        198px 428px #fff, 910px 563px #fff, 641px 1375px #fff, 1653px 93px #fff,
        1894px 1611px #fff, 1754px 1230px #fff, 870px 136px #fff, 1834px 202px #fff,
        1292px 4px #fff, 134px 1135px #fff, 1087px 1695px #fff, 1910px 882px #fff,
        1488px 16px #fff, 602px 266px #fff, 803px 1884px #fff, 1256px 916px #fff,
        1533px 1231px #fff, 1299px 507px #fff, 1314px 133px #fff, 493px 648px #fff,
        1869px 1275px #fff, 176px 1343px #fff, 1985px 1169px #fff, 275px 1804px #fff,
        378px 1660px #fff, 1625px 1300px #fff, 1689px 960px #fff, 1623px 1188px #fff,
        1093px 388px #fff, 1652px 1712px #fff, 1906px 384px #fff, 845px 419px #fff,
        1838px 277px #fff, 961px 71px #fff, 695px 248px #fff, 1916px 914px #fff,
        407px 72px #fff, 1032px 389px #fff, 1247px 154px #fff, 733px 1588px #fff,
        390px 1860px #fff, 540px 141px #fff, 1040px 1992px #fff, 1126px 405px #fff,
        1739px 1707px #fff, 757px 1231px #fff, 1080px 340px #fff, 145px 1228px #fff,
        469px 1740px #fff, 1189px 356px #fff, 1024px 991px #fff, 1751px 319px #fff,
        1891px 1171px #fff, 921px 143px #fff, 1608px 1708px #fff, 197px 553px #fff,
        1167px 1578px #fff, 866px 1px #fff, 182px 1402px #fff, 1897px 1021px #fff,
        1566px 985px #fff, 1412px 859px #fff, 1751px 985px #fff, 1645px 154px #fff,
        1975px 54px #fff, 778px 609px #fff, 844px 902px #fff, 1110px 497px #fff,
        1974px 347px #fff, 548px 1927px #fff, 693px 1982px #fff, 1962px 1676px #fff,
        495px 278px #fff, 1458px 62px #fff, 1105px 1080px #fff, 1271px 1433px #fff,
        72px 961px #fff, 639px 1093px #fff, 195px 298px #fff, 1666px 285px #fff,
        747px 1484px #fff, 1920px 572px #fff, 682px 445px #fff, 1318px 397px #fff,
        1311px 314px #fff, 1672px 994px #fff, 907px 1844px #fff, 835px 80px #fff,
        239px 1946px #fff, 589px 1177px #fff, 1740px 1045px #fff, 888px 425px #fff`,
          '&:after': {
            boxShadow: `1774px 3429px #fff, 1487px 495px #fff, 1855px 613px #fff,
            526px 1860px #fff, 577px 1571px #fff, 686px 1802px #fff, 474px 165px #fff,
            13px 1313px #fff, 1022px 1217px #fff, 1199px 671px #fff, 1326px 1718px #fff,
            206px 1539px #fff, 1997px 1163px #fff, 1630px 1946px #fff, 441px 1649px #fff,
            1807px 1335px #fff, 396px 1319px #fff, 514px 1193px #fff, 669px 389px #fff,
            1980px 178px #fff, 977px 712px #fff, 1235px 1369px #fff, 1719px 558px #fff,
            401px 490px #fff, 440px 1270px #fff, 1548px 21px #fff, 313px 1188px #fff,
            1837px 1542px #fff, 709px 557px #fff, 697px 283px #fff, 278px 643px #fff,
            1650px 181px #fff, 169px 1610px #fff, 47px 272px #fff, 1747px 80px #fff,
            1239px 1730px #fff, 219px 64px #fff, 305px 1383px #fff, 1318px 1065px #fff,
            33px 1727px #fff, 1494px 21px #fff, 1745px 67px #fff, 327px 758px #fff,
            656px 1763px #fff, 192px 1413px #fff, 332px 425px #fff, 1095px 1000px #fff,
            1510px 1148px #fff, 1801px 1191px #fff, 1633px 1747px #fff,
            1756px 1842px #fff, 1948px 1726px #fff, 964px 1065px #fff, 1766px 759px #fff,
            343px 1175px #fff, 666px 107px #fff, 107px 1926px #fff, 637px 1405px #fff,
            1657px 1845px #fff, 1208px 1838px #fff, 1926px 1456px #fff,
            1710px 1641px #fff, 1194px 1817px #fff, 1377px 1408px #fff,
            1265px 878px #fff, 188px 1917px #fff, 1461px 1059px #fff, 1568px 361px #fff,
            1701px 887px #fff, 1667px 1263px #fff, 1316px 1670px #fff, 1145px 997px #fff,
            1855px 1831px #fff, 205px 1810px #fff, 223px 209px #fff, 1013px 708px #fff,
            1560px 1728px #fff, 144px 176px #fff, 716px 1187px #fff, 1168px 301px #fff,
            1754px 740px #fff, 981px 1581px #fff, 1323px 475px #fff, 793px 833px #fff,
            1752px 1344px #fff, 1463px 1011px #fff, 3px 457px #fff, 1435px 1437px #fff,
            550px 73px #fff, 396px 1369px #fff, 1383px 143px #fff, 151px 1177px #fff,
            820px 76px #fff, 665px 1907px #fff, 94px 1648px #fff, 762px 723px #fff,
            1604px 1208px #fff, 97px 1925px #fff, 675px 29px #fff, 1500px 1670px #fff,
            728px 560px #fff, 482px 1265px #fff, 1084px 1157px #fff, 553px 1104px #fff,
            1216px 1474px #fff, 747px 1341px #fff, 1370px 1615px #fff, 1572px 364px #fff,
            198px 428px #fff, 910px 563px #fff, 641px 1375px #fff, 1653px 93px #fff,
            1894px 1611px #fff, 1754px 1230px #fff, 870px 136px #fff, 1834px 202px #fff,
            1292px 4px #fff, 134px 1135px #fff, 1087px 1695px #fff, 1910px 882px #fff,
            1488px 16px #fff, 602px 266px #fff, 803px 1884px #fff, 1256px 916px #fff,
            1533px 1231px #fff, 1299px 507px #fff, 1314px 133px #fff, 493px 648px #fff,
            1869px 1275px #fff, 176px 1343px #fff, 1985px 1169px #fff, 275px 1804px #fff,
            378px 1660px #fff, 1625px 1300px #fff, 1689px 960px #fff, 1623px 1188px #fff,
            1093px 388px #fff, 1652px 1712px #fff, 1906px 384px #fff, 845px 419px #fff,
            1838px 277px #fff, 961px 71px #fff, 695px 248px #fff, 1916px 914px #fff,
            407px 72px #fff, 1032px 389px #fff, 1247px 154px #fff, 733px 1588px #fff,
            390px 1860px #fff, 540px 141px #fff, 1040px 1992px #fff, 1126px 405px #fff,
            1739px 1707px #fff, 757px 1231px #fff, 1080px 340px #fff, 145px 1228px #fff,
            469px 1740px #fff, 1189px 356px #fff, 1024px 991px #fff, 1751px 319px #fff,
            1891px 1171px #fff, 921px 143px #fff, 1608px 1708px #fff, 197px 553px #fff,
            1167px 1578px #fff, 866px 1px #fff, 182px 1402px #fff, 1897px 1021px #fff,
            1566px 985px #fff, 1412px 859px #fff, 1751px 985px #fff, 1645px 154px #fff,
            1975px 54px #fff, 778px 609px #fff, 844px 902px #fff, 1110px 497px #fff,
            1974px 347px #fff, 548px 1927px #fff, 693px 1982px #fff, 1962px 1676px #fff,
            495px 278px #fff, 1458px 62px #fff, 1105px 1080px #fff, 1271px 1433px #fff,
            72px 961px #fff, 639px 1093px #fff, 195px 298px #fff, 1666px 285px #fff,
            747px 1484px #fff, 1920px 572px #fff, 682px 445px #fff, 1318px 397px #fff,
            1311px 314px #fff, 1672px 994px #fff, 907px 1844px #fff, 835px 80px #fff,
            239px 1946px #fff, 589px 1177px #fff, 1740px 1045px #fff, 888px 425px #fff`,
          },
        }}
      />
      <Box
        id="stars3"
        sx={{
          width: '3px',
          height: '3px',
          background: 'transparent',
          boxShadow: `847px 1630px #fff, 910px 1844px #fff, 546px 1170px #fff,
        1479px 1494px #fff, 1018px 222px #fff, 1902px 102px #fff, 916px 1763px #fff,
        1911px 1012px #fff, 513px 766px #fff, 18px 1803px #fff, 1746px 430px #fff,
        1373px 1109px #fff, 1858px 358px #fff, 1254px 579px #fff, 1379px 1224px #fff,
        1249px 744px #fff, 1362px 934px #fff, 1098px 155px #fff, 1958px 1357px #fff,
        585px 662px #fff, 1627px 1478px #fff, 1117px 8px #fff, 500px 227px #fff,
        1329px 563px #fff, 1591px 824px #fff, 344px 1735px #fff, 284px 269px #fff,
        1067px 811px #fff, 1794px 833px #fff, 671px 679px #fff, 490px 1814px #fff,
        1145px 150px #fff, 118px 1301px #fff, 604px 579px #fff, 1309px 936px #fff,
        742px 465px #fff, 1971px 99px #fff, 1849px 1887px #fff, 190px 1480px #fff,
        981px 186px #fff, 277px 1990px #fff, 454px 1038px #fff, 1978px 941px #fff,
        604px 1295px #fff, 489px 1016px #fff, 1845px 335px #fff, 1429px 878px #fff,
        1783px 162px #fff, 581px 904px #fff, 1981px 828px #fff, 1442px 359px #fff,
        408px 448px #fff, 1333px 1940px #fff, 240px 34px #fff, 1830px 878px #fff,
        1993px 1532px #fff, 468px 1445px #fff, 23px 963px #fff, 394px 668px #fff,
        1447px 996px #fff, 1732px 562px #fff, 1752px 1559px #fff, 1593px 372px #fff,
        226px 1656px #fff, 987px 1832px #fff, 1896px 231px #fff, 1565px 892px #fff,
        1818px 413px #fff, 1375px 667px #fff, 1007px 1398px #fff, 201px 1756px #fff,
        1429px 1179px #fff, 94px 1165px #fff, 883px 706px #fff, 1693px 869px #fff,
        387px 336px #fff, 683px 1944px #fff, 1662px 1383px #fff, 1718px 807px #fff,
        759px 1845px #fff, 1187px 1407px #fff, 470px 945px #fff, 1245px 326px #fff,
        1032px 1593px #fff, 1737px 1480px #fff, 1517px 764px #fff, 385px 198px #fff,
        1798px 1412px #fff, 255px 71px #fff, 257px 210px #fff, 1517px 814px #fff,
        952px 1766px #fff, 1490px 810px #fff, 582px 670px #fff, 1247px 593px #fff,
        1273px 360px #fff, 16px 1375px #fff, 1809px 1913px #fff, 1142px 806px #fff,
        287px 135px #fff`,
          '&:after': {
            boxShadow: `847px 1630px #fff, 910px 1844px #fff, 546px 1170px #fff,
          1479px 1494px #fff, 1018px 222px #fff, 1902px 102px #fff, 916px 1763px #fff,
          1911px 1012px #fff, 513px 766px #fff, 18px 1803px #fff, 1746px 430px #fff,
          1373px 1109px #fff, 1858px 358px #fff, 1254px 579px #fff, 1379px 1224px #fff,
          1249px 744px #fff, 1362px 934px #fff, 1098px 155px #fff, 1958px 1357px #fff,
          585px 662px #fff, 1627px 1478px #fff, 1117px 8px #fff, 500px 227px #fff,
          1329px 563px #fff, 1591px 824px #fff, 344px 1735px #fff, 284px 269px #fff,
          1067px 811px #fff, 1794px 833px #fff, 671px 679px #fff, 490px 1814px #fff,
          1145px 150px #fff, 118px 1301px #fff, 604px 579px #fff, 1309px 936px #fff,
          742px 465px #fff, 1971px 99px #fff, 1849px 1887px #fff, 190px 1480px #fff,
          981px 186px #fff, 277px 1990px #fff, 454px 1038px #fff, 1978px 941px #fff,
          604px 1295px #fff, 489px 1016px #fff, 1845px 335px #fff, 1429px 878px #fff,
          1783px 162px #fff, 581px 904px #fff, 1981px 828px #fff, 1442px 359px #fff,
          408px 448px #fff, 1333px 1940px #fff, 240px 34px #fff, 1830px 878px #fff,
          1993px 1532px #fff, 468px 1445px #fff, 23px 963px #fff, 394px 668px #fff,
          1447px 996px #fff, 1732px 562px #fff, 1752px 1559px #fff, 1593px 372px #fff,
          226px 1656px #fff, 987px 1832px #fff, 1896px 231px #fff, 1565px 892px #fff,
          1818px 413px #fff, 1375px 667px #fff, 1007px 1398px #fff, 201px 1756px #fff,
          1429px 1179px #fff, 94px 1165px #fff, 883px 706px #fff, 1693px 869px #fff,
          387px 336px #fff, 683px 1944px #fff, 1662px 1383px #fff, 1718px 807px #fff,
          759px 1845px #fff, 1187px 1407px #fff, 470px 945px #fff, 1245px 326px #fff,
          1032px 1593px #fff, 1737px 1480px #fff, 1517px 764px #fff, 385px 198px #fff,
          1798px 1412px #fff, 255px 71px #fff, 257px 210px #fff, 1517px 814px #fff,
          952px 1766px #fff, 1490px 810px #fff, 582px 670px #fff, 1247px 593px #fff,
          1273px 360px #fff, 16px 1375px #fff, 1809px 1913px #fff, 1142px 806px #fff,
          287px 135px #fff`,
          },
        }}
      />
      {isLoading ? <Rocket /> : children}
    </Box>
  );
};

export { StarsBG };
