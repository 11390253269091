import { Box, Grid, Slide, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { PageEntrance } from '../loaders';

import { ScrollWrap } from '../components/ScrollWrap';
import { DevProfile } from '../components/DevProfile';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setHomePageLoaded } from '../store/reducers';

const Home: FC = () => {
  const dispatch = useDispatch();
  const animate = useSelector((state: RootState) => state.ui.animate.homepage);

  const [startFloating, setStartFloating] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStartFloating(true);
    }, 3000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setHomePageLoaded(true));
    }, 5000);
  }, [dispatch]);

  return (
    <PageEntrance title="" animate={animate}>
      <ScrollWrap>
        <Grid container spacing={2} rowGap={4}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              sx={{
                textAlign: { md: 'center', xs: 'left' },
              }}
            >
              Zac Salazar <br />
              Full Stack Developer
            </Typography>
          </Grid>
          {animate ? (
            <Slide direction="right" in={true} timeout={3500}>
              <Grid item xs={12} md={6} textAlign="center">
                <Box
                  component={'img'}
                  src="/images/moon-zac-min-1.png"
                  alt={'my-face'}
                  sx={{
                    height: { md: '400px', sm: '300px', xs: '200px' },
                    width: { md: '400px', sm: '300px', xs: '200px' },
                  }}
                  id={startFloating ? 'astronaut' : ''}
                />
              </Grid>
            </Slide>
          ) : (
            <Grid item xs={12} md={6} textAlign="center">
              <Box
                component={'img'}
                src="/images/moon-zac-min-1.png"
                alt={'my-face'}
                sx={{
                  height: { md: '400px', sm: '300px', xs: '200px' },
                  width: { md: '400px', sm: '300px', xs: '200px' },
                }}
                id={startFloating ? 'astronaut' : ''}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <DevProfile />
          </Grid>
        </Grid>
      </ScrollWrap>
    </PageEntrance>
  );
};

export default Home;
