const reviews = [
  {
    name: 'Phil, WorkflowU',
    review: `Zac's pivotal technical expertise propels WorkflowU's success, excelling in Fullstack Development and setting a standard for automating service-based businesses with innovative problem-solving.`,
  },
  {
    name: 'Sharney, MashMedia',
    review:
      'Zac is amazing! He is always up for a challenge and looking outside the square to find solutions and come up with ideas to make websites stand out from the crowd. The web projects I worked on with Zac were all amazing. I would recommend Zac to anyone! 10/10',
  },
  {
    name: 'Mark, HoneyGrid | MVP Mailhouse',
    review:
      "Zac is a really fun developer to work with. His energy for solving problems with technology is his greatest asset. I'm always impressed by his creativity and hard work. Zac has knowledge and experience in almost every area of technology because he's worked on so many projects over the years ",
  },
  {
    name: 'James, HoneyGrid',
    review:
      "If you're looking for a fullstack dev to join your team, definitely take a look at Zac. He's great at building POCs, particularly with new tech. I've worked with him on a React, Redux, and Firebase stack. Pick him up before someone else does!",
  },
];

export { reviews };
