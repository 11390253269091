import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project, ProjectTag } from '../../resources/types';

export interface ProjectsInitialState {
  list: Project[];
  selectedTag: ProjectTag;
  preview: Project | null;
}

const initialState: ProjectsInitialState = {
  list: [],
  selectedTag: ProjectTag.ALL,
  preview: null,
};
export const projectSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.list = action.payload;
    },
    setTag: (state, action: PayloadAction<ProjectTag>) => {
      state.selectedTag = action.payload;
    },
    setPreview: (state, action: PayloadAction<Project | null>) => {
      state.preview = action.payload;
    },
  },
});

export const { setProjects, setTag, setPreview } = projectSlice.actions;
const ProjectReducer = projectSlice.reducer;

export { ProjectReducer };
