import {
  Box,
  Button,
  Grid,
  Typography,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Footer: FC = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();

  const { pathname: current } = useLocation();

  const hideContact = current.slice(1) === '' || current.slice(1) === 'contact';

  const d = new Date();
  let year = d.getFullYear();

  const copyright = isMobile
    ? `© ${year} | Zac`
    : `Copyright © ${year} | Zac Salazar`;

  const handleContact = () => {
    navigate('/contact');
  };

  return (
    <Box sx={{ flexGrow: 1, alignItems: 'center', p: 2 }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        marginBottom="10px"
      >
        <Grid item xs={1} md={4} />
        <Grid item xs={hideContact ? 12 : 5} md={4}>
          <Typography variant="body1" textAlign="center">
            {copyright}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} textAlign="right">
          <Zoom in={!hideContact}>
            <Button variant="outlined" onClick={handleContact}>
              contact
            </Button>
          </Zoom>
        </Grid>
      </Grid>
    </Box>
  );
};

export { Footer };
