import { Grid } from '@mui/material';
import { FC, useEffect } from 'react';
import { FilterButton } from '../FilterButton';
import { ProjectCard } from '../ProjectCard';
import { projects, projectTags } from '../../content/';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setProjects } from '../../store/reducers';
import { ScrollWrap } from '../ScrollWrap';

const PortfolioFilter: FC = () => {
  const dispatch = useDispatch();

  const projectList = useSelector((state: RootState) => state.project.list);
  const projectFilter = useSelector(
    (state: RootState) => state.project.selectedTag
  );

  useEffect(() => {
    const filteredProjects = projects.filter((project) => {
      return (
        project.tags &&
        (project.tags.includes(projectFilter) || projectFilter === 'All')
      );
    });
    dispatch(setProjects(filteredProjects));
  }, [dispatch, projectFilter]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {projectTags.map((tag) => {
            return <FilterButton key={tag} title={tag} />;
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ScrollWrap>
          <Grid container spacing={2}>
            {projectList.map((project, index) => {
              return (
                <ProjectCard
                  key={project.key}
                  title={project.title}
                  description={project.description}
                  tech={project.tech}
                  link={project.link}
                  code={project.code}
                  delay={index * 250}
                  screenshot={project.screenshot}
                />
              );
            })}
          </Grid>
        </ScrollWrap>
      </Grid>
    </Grid>
  );
};

export { PortfolioFilter };
