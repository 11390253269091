import { Grid, Slider, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { SkillStats } from '../../resources/types';
import { getSliderColor } from '../../utils/getSliderColor';

const SkillSlider: FC<SkillStats> = ({ skill, level }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setValue(level);
    }, 800); // Adjust the delay as needed

    return () => {
      clearTimeout(animationTimeout);
    };
  }, [level]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">{skill}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Slider
          value={value}
          sx={{
            cursor: 'default',
            height: '15px',
            color: getSliderColor(value),
            '& .MuiSlider-thumb': {
              display: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export { SkillSlider };
