import { FC } from 'react';
import { PageEntrance } from '../loaders';
import { Job } from '../components/Job';
import { Grid } from '@mui/material';
import { experience } from '../content/';
import { ScrollWrap } from '../components/ScrollWrap';

const Experience: FC = () => {
  return (
    <PageEntrance title="experience">
      <ScrollWrap>
        <Grid container spacing={2}>
          {experience.map((job) => {
            return (
              <Grid item xs={12} key={job.company}>
                <Job {...job} />
              </Grid>
            );
          })}
        </Grid>
      </ScrollWrap>
    </PageEntrance>
  );
};

export default Experience;
