import { Project, ProjectTag } from '../resources/types';

export const projects: Project[] = [
  {
    key: 'bitewheel-wheel',
    title: 'BiteWheel',
    description:
      'Bitewheel is the app that takes the guesswork out of dining. Discover new places to eat nearby with just a spin, perfect for when you cannot decide where to go.',
    tags: [ProjectTag.ALL, ProjectTag.REACT, ProjectTag.SOFTWARE],
    tech: ['NextJS', 'Redux', 'TypeScript', 'MaterialUI', 'React'],
    link: 'https://www.bitewheel.com/',
    code: 'https://github.com/zackenichi/bitewheel',
    screenshot: '/screenshots/bitewheel.jpg',
  },
  {
    key: 'random-wheel',
    title: 'Wheel of names clone',
    description:
      'A React app for managing a list of names with a roulette feature to randomly select a name. Users can add, edit, and remove names in real-time, with a dynamic and interactive interface.',
    tags: [ProjectTag.ALL, ProjectTag.REACT, ProjectTag.SOFTWARE],
    tech: ['React', 'Redux', 'TypeScript', 'MaterialUI'],
    link: 'https://random.zacsalazar.com/',
    code: 'https://github.com/zackenichi/wheel-of-names',
    screenshot: '/screenshots/random.jpg',
  },
  {
    key: 'freedom-wall',
    title: 'Freedom Wall',
    description:
      'Freedom Wall is a simple web application that enables users to post messages anonymously. The unique feature of this project is that messages automatically disappear after 10 minutes.',
    tags: [ProjectTag.ALL, ProjectTag.REACT, ProjectTag.SOFTWARE],
    tech: ['React', 'Redux', 'TypeScript', 'MaterialUI', 'Firebase'],
    link: 'https://board.zacsalazar.com/',
    code: 'https://github.com/zackenichi/freedom-wall',
    screenshot: '/screenshots/freedom-wall.png',
  },
  {
    key: 'darkmode-swith-redux',
    title: 'Darkmode switch',
    description:
      'React application that includes a Dark Mode Switch component. The Dark Mode Switch allows users to toggle between light and dark themes in the application.',
    tags: [ProjectTag.ALL, ProjectTag.REACT],
    tech: ['React', 'Redux', 'TypeScript', 'MaterialUI'],
    link: 'https://darkmode-switch-redux.vercel.app/',
    code: 'https://github.com/zackenichi/darkmode-switch-redux',
    screenshot: '/screenshots/darkmode-switch.jpg',
  },
  {
    key: 'poke-counter',
    title: 'Pokemon Element Counter',
    description: `The Pokemon Element Counter is a React application that allows users to view and compare the weaknesses of different Pokemon types. It provides a visual representation of the elements and their corresponding weaknesses.`,
    tags: [ProjectTag.ALL, ProjectTag.REACT, ProjectTag.SOFTWARE],
    tech: ['React', 'Redux', 'MaterialUI'],
    link: 'https://poke-counter.vercel.app/',
    code: 'https://github.com/zackenichi/darkmode-switch-redux',
    screenshot: '/screenshots/poke-counter.png',
  },
  {
    key: 'stripe-payment-integration',
    title: 'Stripe Payment Integration',
    description: `This project demonstrates the integration of Stripe's payment processing capabilities into a web application, combining frontend and backend code to enable seamless online payments.`,
    tags: [ProjectTag.ALL, ProjectTag.NODE],
    tech: ['React', 'Redux', 'Node', 'Express', 'Stripe'],
    code: 'https://github.com/zackenichi/stripe-typescript',
    screenshot: '/screenshots/stripe-payment.png',
  },
  {
    key: 'theme-picker-react',
    title: 'Dynamic Theme Picker',
    description: `This prototype showcases the ability to dynamically set page styles in real-time. It provides several customization options including colors and font sizes.`,
    tags: [ProjectTag.ALL, ProjectTag.REACT],
    tech: ['React', 'JavaScript', 'MaterialUI'],
    code: 'https://github.com/zackenichi/theme-picker',
    link: 'https://theme-picker.vercel.app/',
    screenshot: '/screenshots/theme-picker.jpg',
  },
  {
    key: 'lightsaber-battles',
    title: 'Lightsaber Battles',
    description: `The "Lightsaber Battles" app enables real-time judging of live lightsaber battles with an interactive scoring system. Spectators can follow the battle progress and view scores as the duels unfold, enhancing the excitement and engagement of the event.`,
    tags: [ProjectTag.ALL, ProjectTag.REACT, ProjectTag.SOFTWARE],
    tech: ['Javascript', 'HTML', 'CSS'],
    link: 'https://codepen.io/zackenichi/full/YzpxMJG',
    code: 'https://codepen.io/zackenichi/pen/YzpxMJG',
    screenshot: '/screenshots/lightsaber-battles.png',
  },
  {
    key: 'jellyfish-lighting-wordpress',
    title: 'Jellyfish Lighting',
    description: `This project is a WordPress website for a lighting company that showcases a wide range of lighting products and services. Visitors can explore their catalog, learn about their expertise, and request quotes. It's a seamless platform for discovering high quality lighting solutions.`,
    tags: [ProjectTag.ALL, ProjectTag.WEB],
    tech: ['WordPress', 'PHP', 'HTML', 'CSS', 'jQuery'],
    link: 'https://jellyfishlighting.com/',
    screenshot: '/screenshots/jellyfish-lighting.png',
  },
  {
    key: 'coinflip-app',
    title: 'Coinflip',
    description: `The coin flip app provides a quick and realistic way to simulate the flipping of a coin. It's perfect for decision-making, games, or any situation where a random outcome is needed.`,
    tags: [ProjectTag.ALL, ProjectTag.REACT],
    tech: ['React', 'TypeScript', 'MaterialUI'],
    code: 'https://github.com/zackenichi/coinflip-app',
    link: 'https://coinflip-app-gamma.vercel.app/',
    screenshot: '/screenshots/coinflip.jpg',
  },
  {
    key: 'dynamic-field-picker',
    title: 'Dynamic Field Picker',
    description: `The dynamic field picker prototype enables users to easily select and insert dynamic fields into email templates. Using regex and a simulated database call, it streamlines the process of creating personalized and dynamic email content.`,
    tags: [ProjectTag.ALL, ProjectTag.REACT],
    tech: ['React', 'JavaScript', 'MaterialUI'],
    code: 'https://github.com/zackenichi/field-picker',
    link: 'https://field-picker-zackenichi.vercel.app/',
    screenshot: '/screenshots/field-picker.jpg',
  },
  {
    key: 'ad-generator',
    title: 'Advertisement Generator',
    description: `The ad generator prototype utilizes ChatGPT, website data, and scraped information to generate personalized advertisements that align with the company's tone and target audience.`,
    tags: [ProjectTag.ALL, ProjectTag.SOFTWARE, ProjectTag.AI],
    tech: ['Cheerio', 'Node', 'React', 'OpenAI'],
    code: 'https://github.com/zackenichi/chatgpt-adgen',
    link: 'https://chatgpt-adgen.vercel.app/',
    screenshot: '/screenshots/ad-generator.png',
  },
  {
    key: 'slider-animation',
    title: 'Slider animation',
    description: `The slider animation adds a visually appealing slide-in effect to range input elements, enhancing the user experience with a dynamic and engaging transition. It brings elegance and captivates users, making it a valuable addition to any interface that utilizes range inputs.`,
    tags: [ProjectTag.ALL, ProjectTag.REACT],
    tech: ['React', 'JavaScript', 'MaterialUI'],
    code: 'https://github.com/zackenichi/slider-animation',
    link: 'https://slider-animation-bice.vercel.app/',
    screenshot: '/screenshots/slider-animation.png',
  },
  {
    key: 'signature-input',
    title: 'Signature Input',
    tags: [ProjectTag.ALL, ProjectTag.SOFTWARE, ProjectTag.REACT],
    description: `The signature input implementation enables users to digitally capture and input their signatures, eliminating the need for physical signatures and streamlining digital workflows. It provides a convenient and intuitive way to sign documents, forms, or agreements on a digital platform.`,
    tech: ['React', 'JavaScript', 'MaterialUI'],
    code: 'https://github.com/zackenichi/signature-system',
    link: 'https://signature-system.vercel.app/',
    screenshot: '/screenshots/signature-input.png',
  },
  {
    key: 'link-shortener-node',
    title: 'Link Shortener Node',
    tags: [ProjectTag.ALL, ProjectTag.SOFTWARE, ProjectTag.NODE],
    description: `The Link Shortener Node project is a Node.js-based tool for generating concise and shareable short links from long URLs. It offers efficient URL management and tracking capabilities, making it a valuable asset for optimizing and organizing links in various applications.`,
    tech: ['Node', 'Express', 'MongoDB'],
    code: 'https://github.com/zackenichi/link-shortener',
    screenshot: '/screenshots/node.png',
  },
  {
    key: 'timberline-sharpener-wordpress',
    title: 'Timberline Sharpener',
    description: `This is a highly successful e-commerce shop for a company that provides sharpening services for saw blades and other cutting tools. It showcases their services, expertise, and products, and provides a seamless platform for customers to place orders.`,
    tags: [ProjectTag.ALL, ProjectTag.WEB],
    tech: ['WordPress', 'PHP', 'HTML', 'CSS', 'jQuery', 'Woocommerce'],
    link: 'https://timberlinesharpener.com/',
    screenshot: '/screenshots/timber.jpg',
  },
  {
    key: 'login-greetings',
    title: 'Login Greetings',
    tags: [ProjectTag.ALL, ProjectTag.REACT],
    description: `The login greetings prototype provides a personalized welcome message to users after they log in. It enhances the user experience by creating a more welcoming and engaging environment.`,
    tech: ['React', 'JavaScript', 'MaterialUI'],
    code: 'https://github.com/zackenichi/login-greetings',
    link: 'https://login-greetings.vercel.app/',
    screenshot: '/screenshots/login-greetings.png',
  },
  {
    key: 'zac-chatbot',
    title: 'Chatbot',
    tags: [ProjectTag.ALL, ProjectTag.AI],
    description: `The Developer Service Enquiry Chatbot is an intelligent assistant that provides prompt and accurate support for developer service inquiries and pricing. It offers a seamless and efficient way to obtain information about services and pricing plans, enhancing the overall customer experience.`,
    tech: ['React', 'JavaScript', 'MaterialUI', 'Chatbot-Toolkit'],
    code: 'https://github.com/zackenichi/zac-chatbot',
    link: 'https://zacbot.vercel.app/',
    screenshot: '/screenshots/zac-chatbot.jpg',
  },
  {
    key: 'google-business-search',
    title: 'Business Search',
    tags: [ProjectTag.ALL, ProjectTag.NODE],
    description: `The Google Place Search integration proof of concept is a Node.js project that demonstrates the seamless integration of Google's Place Search API into web applications. It showcases the ability to search for places, retrieve detailed information, display results, and provide autocomplete suggestions based on user queries.`,
    tech: ['Node', 'Express', 'Google Cloud', 'React'],
    code: 'https://github.com/zackenichi/Gplaces-NodeJS',
    screenshot: '/screenshots/google-business-search.jpg',
  },
];

export const projectTags = [
  ProjectTag.ALL,
  ProjectTag.NODE,
  ProjectTag.REACT,
  ProjectTag.WEB,
  ProjectTag.SOFTWARE,
  ProjectTag.AI,
];
