import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setDisplayModal } from '../../store/reducers';
import TechLogo from '../TechLogo/TechLogo';

const ProjectDetails: FC = () => {
  const dispatch = useDispatch();

  const openModal = useSelector((state: RootState) => state.ui.displayModal);
  const preview = useSelector((state: RootState) => state.project.preview);

  const handleClose = () => {
    dispatch(setDisplayModal(false));
  };

  const handleShowDemo = () => {
    const link = preview?.link || 'https://example.com';

    window.open(link, '_blank');
  };

  const handleShowCode = () => {
    const link = preview?.code || 'https://example.com';

    window.open(link, '_blank');
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { md: 594, xs: '80%' },
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          outline: 'none',
          p: 2,
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item md={6} xs={8}>
            <Typography variant="h3" color="secondary">
              {preview?.title || '-'}
            </Typography>
          </Grid>
          <Grid item md={6} xs={4} sx={{ textAlign: 'right' }}>
            <IconButton onClick={handleClose}>
              <CloseIcon
                sx={{ color: '#777D83', cursor: 'pointer' }}
                fontSize="small"
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              {preview?.description || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              component={'img'}
              src={
                preview?.screenshot
                  ? preview.screenshot
                  : 'https://via.placeholder.com/400x400'
              }
              alt={preview?.title}
              sx={{
                height: { md: '400px', xs: '200px' },
                width: '100%',
              }}
            />
          </Grid>
          {preview?.tech && (
            <Grid item xs={8}>
              {preview.tech.map((tech) => {
                return <TechLogo name={tech} key={tech} />;
              })}
            </Grid>
          )}
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            {preview?.code && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleShowCode}
                sx={{ marginRight: '5px' }}
              >
                Source
              </Button>
            )}
            {preview?.link && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleShowDemo}
              >
                Visit
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export { ProjectDetails };
