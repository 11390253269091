import { FC } from 'react';

import { Box, Slide } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface PageEntranceProps {
  children: React.ReactNode;
  title: string;
  animate?: boolean;
}

const PageEntrance: FC<PageEntranceProps> = ({
  children,
  title,
  animate = true,
}) => {
  const { pathname: current } = useLocation();

  const show = title.toLowerCase() === current.slice(1);

  if (!animate) return <>{children}</>;

  return (
    <Slide
      in={show}
      direction="up"
      style={{ transformOrigin: '0 0 0' }}
      {...(show ? { timeout: 1000 } : {})}
    >
      <Box>{children}</Box>
    </Slide>
  );
};

export { PageEntrance };
