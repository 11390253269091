import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import AdjustIcon from '@mui/icons-material/Adjust';
import TechLogo from '../TechLogo/TechLogo';
import { Experience } from '../../resources/types/Experience';
import { CardDivider } from '../ProjectCard';

const Job: FC<Experience> = (props) => {
  const { title, stack, screenshot } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <JobDetails {...props} />
      </Grid>
      <Grid item xs={12} md={6}>
        <JobOverview title={title} stack={stack} screenshot={screenshot} />
      </Grid>
      <CardDivider />
    </Grid>
  );
};

const JobOverview: FC<Partial<Experience>> = ({ title, stack, screenshot }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          component={'img'}
          src={screenshot ? screenshot : 'https://via.placeholder.com/400x400'}
          alt={title}
          sx={{
            height: { md: '400px', xs: '200px' },
            width: '100%',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {stack &&
          stack.map((tech) => {
            return <TechLogo name={tech} key={tech} />;
          })}
      </Grid>
    </Grid>
  );
};

const JobDetails: FC<Experience> = (props) => {
  const {
    title,
    dates,
    company,
    location,
    description,
    responsibilities,
    logo,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" color="primary">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" color="info.main">
          {dates}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <JobEmployer company={company} location={location} logo={logo} />
      </Grid>
      <Grid item xs={12}>
        <Typography>{description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <List>
          {responsibilities?.map((responsibility, index) => {
            return (
              <JobResponsibility
                key={index.toString()}
                responsibility={responsibility}
              />
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

const JobEmployer: FC<{
  company?: string;
  location?: string;
  logo?: string;
}> = ({ company, location, logo }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} textAlign="center">
        <Box
          component={'img'}
          src={logo ? logo : 'https://via.placeholder.com/40x40'}
          alt={'employer-logo'}
          sx={{
            height: '40px',
            width: '40px',
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h3" color="info.main">
          {company || '-'}
        </Typography>
        <Typography variant="body2" color="info.main">
          {location || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};

const JobResponsibility: FC<{ responsibility: string }> = ({
  responsibility,
}) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon>
        <AdjustIcon color="primary" fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={responsibility} />
    </ListItem>
  );
};

export { Job };
