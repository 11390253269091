import { Box, Grid, Grow, Typography } from '@mui/material';
import { FC } from 'react';
import { Project } from '../../resources/types';
import { StyledCard } from '../StyledCard';
import { useDispatch } from 'react-redux';
import { setDisplayModal, setPreview } from '../../store/reducers';

interface ProjectCardProps extends Partial<Project> {
  delay: number;
}

const CardDivider: FC = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        borderBottom: '2px black solid',
        marginTop: '10px',
        marginBottom: '10px',
      }}
    />
  );
};

const ProjectCard: FC<ProjectCardProps> = ({
  title,
  description,
  tech,
  delay,
  link,
  code,
  screenshot,
}) => {
  const dispatch = useDispatch();

  const handlePreview = () => {
    dispatch(
      setPreview({
        title,
        description,
        tech,
        link,
        code,
        screenshot,
      } as Project)
    );
    dispatch(setDisplayModal(true));
  };

  return (
    <Grow in timeout={1000 + delay}>
      <Grid item md={4} xs={12} sx={{ cursor: 'pointer' }}>
        <StyledCard>
          <Box onClick={handlePreview}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="secondary">
                  {title}
                </Typography>
              </Grid>
              <CardDivider />

              <Grid item xs={12}>
                <Box
                  component={'img'}
                  src={
                    screenshot
                      ? screenshot
                      : 'https://via.placeholder.com/200x200'
                  }
                  alt={title}
                  sx={{
                    height: '200px',
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </StyledCard>
      </Grid>
    </Grow>
  );
};

export { ProjectCard, CardDivider };
