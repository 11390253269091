import { Box, Grid, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { PageEntrance } from '../loaders';
import { ContactForm } from '../components/ContactForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setContactPageLoaded } from '../store/reducers';

const Contact: FC = () => {
  const dispatch = useDispatch();
  const animate = useSelector(
    (state: RootState) => state.ui.animate.contactpage
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(setContactPageLoaded(true));
    }, 5000);
  }, [dispatch]);

  return (
    <PageEntrance title="contact" animate={animate}>
      <Grid container spacing={2} textAlign="center">
        <Grid item xs={12}>
          <Typography variant="h1">Get in touch</Typography>
        </Grid>
        <Grid item xs={12} marginBottom="50px">
          <Typography variant="body1">
            Contact me today to discuss your project and experience the
            difference of having a skilled Full Stack Developer by your side.
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} textAlign="center">
          <Box
            component={'img'}
            src="/images/moon-zac-min-1.png"
            alt={'my-face'}
            sx={{
              height: { md: '400px', sm: '300px', xs: '200px' },
              width: { md: '400px', sm: '300px', xs: '200px' },
            }}
            id="astronaut"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ContactForm />
        </Grid>
      </Grid>
    </PageEntrance>
  );
};

export default Contact;
