import { Card, CardContent, Grid, Grow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { reviews } from '../../content';

const Reviews: FC = () => {
  const [currentReview, setCurrentReview] = useState(
    Math.floor(Math.random() * reviews.length)
  );
  const [show, setShow] = useState(false);

  //   time before first review shows up
  useEffect(() => {
    const initialTimeout = setTimeout(() => {
      setShow(true);
    }, 3000); // Set show to true after 3 seconds

    return () => {
      clearTimeout(initialTimeout);
    };
  }, []);

  useEffect(() => {
    const pauseDuration = 3000;
    const reviewDuration = 15000 + pauseDuration; // y seconds + x seconds pause

    const interval = setInterval(() => {
      setShow(false); // Set show to false before switching to a new review
      setTimeout(() => {
        setCurrentReview((prevIndex) => (prevIndex + 1) % reviews.length);
        setShow(true);
      }, pauseDuration); // Set show to true after x seconds
    }, reviewDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Grow in={show} {...(show ? { timeout: 1000 } : { timeout: 800 })}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2">
                {reviews[currentReview].review}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="right">
              <Typography variant="h3" component="h2">
                {reviews[currentReview].name}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grow>
  );
};

export { Reviews };
